import {LINKS} from '@/constants';
import TelegramIcon from '@/icons/telegram-rounded.svg';

export function TelegramIconLink() {
	return (
		<a href={LINKS.telegram} rel="noopener noreferrer" target="_blank">
			<TelegramIcon width={24} height={24} />
		</a>
	);
}
