import classNames from 'classnames';

import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import {DzenIconLink} from '@/components/common/footer/icons/dzen-icon-link/DzenIconLink';
import {FooterItem} from '@/components/common/footer-item/FooterItem';
import {LINKS} from '@/constants';
import VKTechIcon from '@/icons/vk-tech.svg';

import styles from './Footer.module.css';
import {useGetFooterMap} from './get-footer-map';
import {TelegramIconLink} from './icons/telegram-icon-link/TelegramIconLink';
import {VKIconLink} from './icons/vk-icon-link/VKIconLink';
interface FooterProps {
	className?: string;
}

export default function Footer({className}: FooterProps) {
	const year = new Date().getFullYear();

	const footerMap = useGetFooterMap();

	return (
		<footer className={styles.root}>
			<div className={classNames(styles.footerSection, className)}>
				<div className={styles.wrapper}>
					{footerMap.map(({title, items}) => (
						<div key={title}>
							<Typography variant={VARIANTS.accentText} className={styles.title}>
								{title}
							</Typography>
							{items.map(({href, target, title}) => (
								<FooterItem key={title} title={title} href={href} target={target} />
							))}
						</div>
					))}
				</div>
				<div className={styles.vkBusiness}>
					<VKTechIcon />
				</div>
				<div className={styles.bottomLine}>
					<div className={styles.bottomLineLeft}>
						<a
							href={LINKS.bizPrivacy}
							rel="noopener noreferrer"
							target="_blank"
							className={styles.bottomLink}
						>
							Политика конфиденциальности
						</a>
						<a
							href={LINKS.bizTermsofuse}
							rel="noopener noreferrer"
							target="_blank"
							className={styles.bottomLink}
						>
							Условия использования
						</a>
					</div>
					<div className={styles.bottomLineRight}>
						<Typography variant={VARIANTS.text} className={styles.bottomLineText}>
							Москва, 125167, Ленинградский пр., д. 39, стр. 79 © {year} VK
						</Typography>
						<div className={styles.icons}>
							<VKIconLink />
							<DzenIconLink />
							<TelegramIconLink />
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
