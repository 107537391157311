import React from 'react';

import {Targets} from '../../../../types/common';
import styles from './FooterItem.module.css';
interface FooterItemProps {
	title: string;
	href: string;
	target?: Targets;
}

export function FooterItem({href, target, title}: FooterItemProps) {
	return (
		<a href={href} target={target} className={styles.root}>
			{title}
		</a>
	);
}
