import {LINKS} from '@/constants';
import DzenForDarkIcon from '@/icons/dzen-for-dark.svg';

export function DzenIconLink() {
	return (
		<a href={LINKS.dzen} rel="noopener noreferrer" target="_blank">
			<DzenForDarkIcon width={24} height={24} />
		</a>
	);
}
