/* eslint-disable max-lines-per-function */
import {Targets} from 'types/common';

import {ANCHORS as WorkMailAnchors} from '@/components/page/vk-work-mail/const';
import {LINKS, PAGES} from '@/constants';

// eslint-disable-next-line max-lines-per-function
export const useGetFooterMap = () => {
	return [
		{
			title: 'Сервисы',
			items: [
				{
					href: PAGES.teams.href,
					title: 'Приложение VK\u00A0Teams',
				},
				{
					href: PAGES.workMail.href,
					title: 'Почта',
				},
				{
					href: PAGES.meetings.href,
					title: 'Видеоконференции',
				},
				{
					href: `${PAGES.workMail.href}#${WorkMailAnchors.CALENDAR}`,
					title: 'Календарь',
				},
				{
					href: PAGES.workDisk.href,
					title: 'Диск',
				},
				{
					href: PAGES.office.href,
					title: 'Документы',
				},
				{
					href: PAGES.tasks.href,
					title: 'Задачи',
				},
				{
					href: PAGES.board.href,
					title: 'Доска',
				},
				{
					href: PAGES.notebook.href,
					title: 'Заметки',
				},
			],
		},
		{
			title: 'Решения',
			items: [
				{
					href: PAGES.onpremise.href,
					title: 'Для размещения на сервере',
				},
				{
					href: PAGES.education.href,
					title: 'Для образования',
				},
				{
					href: PAGES.retail.href,
					title: 'Для розничной торговли',
				},
				{
					href: PAGES.saas.href,
					title: 'Для размещения в облаке',
				},
			],
		},
		{
			title: 'Клиентам',
			items: [
				{
					href: PAGES.tariffs.href,
					title: 'Тарифы',
				},
				{
					href: PAGES.docs.href,
					title: 'Документация',
				},
				{
					href: LINKS.docsSaasSupport,
					target: Targets.blank,
					title: 'Поддержка',
				},
				{
					href: PAGES.security.href,
					title: 'Безопасность',
				},
				{
					href: PAGES.teamsDownload.href,
					title: 'Скачать VK\u00A0Teams',
				},
			],
		},
		{
			title: 'О компании',
			items: [
				{
					href: LINKS.bizBlog,
					target: Targets.blank,
					title: 'Блог',
				},
				{
					href: PAGES.contacts.href,
					title: 'Контакты',
				},
				{
					href: PAGES.partners.href,
					title: 'Партнерам',
				},
			],
		},
	];
};
