import {createMedia} from '@artsy/fresnel';

export const breakpoints = {
	mobile: 0,
	desktop: 1024,
	desktop_xl: 1440,
	desktop_xxl: 1680,
};

const ExampleAppMedia = createMedia({
	breakpoints,
});

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle();

export const {Media, MediaContextProvider} = ExampleAppMedia;
