import classNames from 'classnames';
import React, {LegacyRef, PropsWithChildren} from 'react';

import styles from './Section.module.css';

export enum SECTION_STYLES {
	PRIMARY = 'primary',
	WITH_BREAKPOINTS = 'withBreakpoints',
}

export interface SectionProps {
	style?: SECTION_STYLES;
	className?: string;
	direction?: 'row' | 'column';
	sectionId?: string;
	isFullWidth?: boolean;
	classNameFW?: string;
	sectionRef?: LegacyRef<HTMLElement>;
}

export function Section({
	className,
	children,
	direction = 'row',
	sectionId,
	isFullWidth = false,
	classNameFW,
	sectionRef,
	style = SECTION_STYLES.PRIMARY,
}: PropsWithChildren<SectionProps>) {
	const classes = classNames(
		{
			[styles.root]: style === SECTION_STYLES.PRIMARY,
			[styles.rootWithBreakpoints]: style === SECTION_STYLES.WITH_BREAKPOINTS,
		},
		className,
		{
			[styles.directionColumn]: direction === 'column',
		},
	);
	if (isFullWidth) {
		return (
			<section className={classNameFW} ref={sectionRef} id={sectionId}>
				<div className={classes}>{children}</div>
			</section>
		);
	}
	return (
		<section className={classes} id={sectionId} ref={sectionRef}>
			{children}
		</section>
	);
}

Section.displayName = 'Section';
