import classNames from 'classnames';
import Script from 'next/script';
import {PureComponent} from 'react';

import styles from './PortalHeader.module.css';

const portalHeaderSelector = '[class^="TemplateForHiddenHeader"]';
const isDev = (process.env.APP_ENV || process.env.NODE_ENV) === 'development';
const isMini = process.env.MINI_STAND === 'true';

const scriptHTML = `
	<script type="text/javascript">
		window.portalHeaderNodes = document.querySelector('${portalHeaderSelector}').firstChild.childNodes;
	</script>
`;

const getPortalHeaderHtml = (isLight: boolean) => `
	<!--#include virtual="/rbh/3355?_SITEZONE=47&${
		isLight ? '' : 'PH_ColorTheme=dark&'
	}LoginPage={% firstof login_success_url request.build_absolute_uri|urlencode %}&LogoutPage={{ request.build_absolute_uri|urlencode }}&PH_Version=2&PH_Nonce={{ CSP_NONCE }}LANG=ru_RU" wait="yes" -->
`;

interface PortalHeaderState {}

interface PortalHeaderProps {
	isLight?: boolean;
}

export class PortalHeader extends PureComponent<PortalHeaderProps, PortalHeaderState> {
	constructor(props) {
		super(props);
		this.state = {
			portalHeaderElement: null,
		};
	}

	// @see https://jira.vk.team/browse/BIZ-14150 Пропадает портальная шапка в Chrome/Firefox/Yandex браузерах
	// костыль для SSI, иначе рекат будет затирать всё и рисовать исходное состояние до SSI
	componentDidMount() {
		if (!window.portalHeaderNodes || !window.portalHeaderNodes.length) {
			return;
		}

		const nodesArray = Array.from(window.portalHeaderNodes);

		const portalHeader = document.querySelector(portalHeaderSelector).firstChild;

		while (portalHeader.firstChild) {
			portalHeader.removeChild(portalHeader.firstChild);
		}

		nodesArray.forEach((node) => {
			portalHeader.appendChild(node);
		});
	}

	render() {
		const {isLight} = this.props;

		if (isDev || isMini) {
			return (
				<>
					<div
						id="ph-wrapper"
						data-siteid="191"
						data-sitezone="47"
						data-color-theme={isLight ? 'default' : 'dark'}
						className={classNames(styles.root, isLight && styles.rootLight)}
					/>
					<Script
						src="https://limg.imgsmail.ru/informers/ph/insert-v2.5.js"
						strategy="afterInteractive"
					/>
				</>
			);
		}

		return (
			<>
				<div
					suppressHydrationWarning
					dangerouslySetInnerHTML={{__html: getPortalHeaderHtml(isLight)}}
				/>
				<div suppressHydrationWarning dangerouslySetInnerHTML={{__html: scriptHTML}} />
			</>
		);
	}
}
