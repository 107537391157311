import {LINKS} from '@/constants';
import VKIconForDark from '@/icons/vk-for-dark.svg';

export function VKIconLink() {
	return (
		<a key="vk-icon" href={LINKS.vkCom} rel="noopener noreferrer" target="_blank">
			<VKIconForDark width={24} height={24} />
		</a>
	);
}
